/* export const API_URL =
  "http://localhost:8000/api/" + window.location.hostname + "/";
export const IMG_URL = "http://localhost:8000/"; */

// change before prod build

//export const API_URL = "http://localhost:8000/api/localhost/";

export const API_URL = "/api/" + window.location.hostname + "/";
export const IMG_URL = "/media/";
