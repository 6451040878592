import React, { useEffect, useState } from "react";
/* import { useNavigate } from "react-router-dom"; */
import axios from "axios";
import { API_URL } from "../../constants";
import Login from "./login";
import EditProductModal from "./editProductModal";

const Products = (props) => {
  /*   let navigate = useNavigate(); */
  const [user, setUser] = useState("");
  const [nuggets, setNuggets] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(false);

  const getNuggetswFilter = () => {
    axios.get(API_URL + "allnuggets/").then((res) => setNuggets(res.data));
  };

  //const [orders, setOrders] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    //window.setInterval(checkFolgs, 6000);
    //getFolgs();
    //getStatis();
    getNuggetswFilter();
  }, []);

  /*   const play = () => {
    let audio = new Audio(
      "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3"
    );
    audio.play();
  }; */

  const onDeleteProduct = (nug) => {
    axios
      .delete(API_URL + "products/" + nug.pk + "/")
      .then(() => getNuggetswFilter());
  };

  const onEditProduct = (nug) => {
    setSelectedProduct(nug);
    setIsOpen(true);
  };

  const RegisterLogin = ({ setUser, ...props }) => {
    return (
      <>
        <Login setUser={setUser} />
      </>
    );
  };

  const ProductList = nuggets.map((nugget) => (
    <li className="admin__list__card" key={nugget.pk}>
      {nugget.description} {nugget.description_long} {nugget.einzelpreis}
      <button onClick={() => onEditProduct(nugget)}>e</button>
      <button onClick={() => onDeleteProduct(nugget)}>del</button>
    </li>
  ));
  return (
    <div>
      {user ? (
        <>
          {" "}
          <h1>Produkte</h1>
          <ul className="list">{ProductList}</ul>
          <button onClick={() => setIsOpen(true)}>Create Product</button>
          {isOpen && (
            <EditProductModal
              setIsOpen={setIsOpen}
              getProducts={getNuggetswFilter}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          )}
        </>
      ) : (
        <RegisterLogin setUser={setUser} />
      )}
    </div>
  );
};
//}

export default Products;
