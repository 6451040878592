import React, { useEffect, useState } from "react";
import axios from "axios";
import ShoppingBottomBar from "./shoppingBottomBar";

//import { useHistory } from "react-router-dom";
import Card from "./card";

import { API_URL } from "../constants/index";

function Food(props) {
  const [nuggets, setNuggets] = useState([]);
  const [folgCount, setFolgCount] = useState([]);
  const [folgTotal, setFolgTotal] = useState();

  const [bCompVisible, setCompVisible] = useState(false);
  useEffect(() => {
    const resetState = () => {
      //getNuggets();
      getNuggetswFilter();
      getFolgCount();
      getFolgSum();
    };
    resetState();
  }, []);

  const getNuggetswFilter = () => {
    axios.get(API_URL + "nuggets/").then((res) => setNuggets(res.data));
  };

  const createOrUpdateFolg = (nugget) => {
    let cartId = localStorage.getItem("cartId");
    axios
      .post(API_URL + "folg/", { nugget: nugget, cartId: cartId })
      .then((data) => {
        if (data.data.cartId) {
          localStorage.setItem("cartId", data.data.cartId);
          // works but is ugly find another way
          getFolgCount();
          getFolgSum();
        }
        /*         history.push("detail/" + data.data.basketId); */
      });
  };

  const getFolgCount = () => {
    let cartId = localStorage.getItem("cartId");
    if (cartId) {
      axios.get(API_URL + "basketcount/" + cartId + "/").then((res) => {
        setFolgCount(res.data);
        if (res.data !== 0) {
          setCompVisible(true);
        }
      });
    }
  };

  const getFolgSum = () => {
    let cartId = localStorage.getItem("cartId");
    if (cartId) {
      axios
        .get(API_URL + "folgTotal/" + cartId + "/")
        .then((res) => setFolgTotal(res.data));
    }
  };

  return (
    <div>
      <div className="food__heading">
        <h1 className="food__heading--keks" id="keks">
          Cookies
        </h1>
      </div>

      <div className="grid--1x">
        {!nuggets || nuggets.length <= 0 ? (
          <p>No Food</p>
        ) : (
          nuggets.map((nugget) => (
            <div key={nugget.pk} className="food">
              <Card updateFolg={createOrUpdateFolg} nugget={nugget}></Card>
            </div>
          ))
        )}
      </div>

      {bCompVisible ? (
        <ShoppingBottomBar
          folgTotalProp={folgTotal}
          folgCountProp={folgCount}
        ></ShoppingBottomBar>
      ) : null}
    </div>
  );
}
//}

export default Food;
