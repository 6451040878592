import React, { useEffect } from "react";
import Headerlogo from "../logo.svg";

const Ag = () => {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <a className="btn btn__close blue__bg" href="/">
        Schließen
      </a>

      {/*       <div id="contactBtn" className="contact__btn__fixed">
        <a href="/#contact" className="btn btn__green">
          Kontakt
        </a>
      </div> */}

      <section className="block__ds">
        <div className="btn--header">
          {/*             <a href="#" className="btn btn--secondary">
            Kontakt
          </a> */}
        </div>
        <div className="block__div__img">
          <img
            className="block__img"
            src={Headerlogo}
            alt="Logo of the Company"
          />
        </div>
        <div className="ds__text">
          <h4>
            Allgemeine Geschäftsbedingungen ALLGEMEINE GESCHÄFTSBEDINGUNGEN ----
          </h4>
          <p>
            ÜBERSICHT Diese Website wird von der Keks Fabrik betrieben. Überall
            auf der Webseite beziehen sich die Begriffe "wir", "uns" und
            "unsere/e" auf Keks Fabrik. Keks Fabrik bietet diese Website,
            einschließlich aller Informationen, Tools und Dienste, die auf
            dieser Website verfügbar sind, Ihnen, dem Benutzer, unter der
            Bedingung an, dass Sie alle hier angegebenen Bedingungen,
            Konditionen, Richtlinien und Hinweise akzeptieren. Wenn Sie unsere
            Website besuchen und/oder etwas bei uns kaufen, nutzen Sie unseren
            "Dienst" und erklären sich damit einverstanden, an die folgenden
            Allgemeinen Geschäftsbedingungen ("Allgemeine Geschäftsbedingungen",
            "Bedingungen") gebunden zu sein, einschließlich der zusätzlichen
            Geschäftsbedingungen und Richtlinien, die hierin erwähnt werden
            und/oder per Hyperlink verfügbar sind. Diese Allgemeinen
            Geschäftsbedingungen gelten für alle Benutzer der Website,
            insbesondere Benutzer, die Besucher, Anbieter, Kunden, Händler
            und/oder Verfasser von Inhalten sind. Bitte lesen Sie diese
            Allgemeinen Geschäftsbedingungen sorgfältig durch, bevor Sie auf
            unsere Website zugreifen oder diese benutzen. Durch den Zugriff auf
            oder die Nutzung eines jeglichen Teils der Website erklären Sie sich
            mit diesen Allgemeinen Geschäftsbedingungen einverstanden. Sind Sie
            nicht mit allen Geschäftsbedingungen dieser Vereinbarung
            einverstanden, dürfen Sie nicht auf die Website zugreifen oder
            irgendwelche Dienste nutzen. Wenn diese Allgemeinen
            Geschäftsbedingungen als Angebot betrachtet werden, beschränkt sich
            die Annahme ausdrücklich auf diese Allgemeinen Geschäftsbedingungen.
            Alle neuen Funktionen oder Tools, die zum aktuellen Shop hinzugefügt
            werden, unterliegen ebenfalls den Allgemeinen Geschäftsbedingungen .
            Sie können die aktuellste Version der Allgemeinen
            Geschäftsbedingungen jederzeit auf dieser Seite einsehen. Wir
            behalten uns das Recht vor, Teile dieser Allgemeinen
            Geschäftsbedingungen durch Veröffentlichung von Updates und/oder
            Änderungen unserer Website zu aktualisieren, zu ändern oder zu
            ersetzen. Es liegt in Ihrer Verantwortung, diese Seite regelmäßig
            auf mögliche Änderungen zu überprüfen. Indem Sie die Website nach
            der Veröffentlichung von irgendwelchen Änderungen weiterhin nutzen
            oder weiterhin darauf zugreifen, gilt dies als Annahme dieser
            Änderungen. Unser Shop wird auf Shopify Inc. gehostet. Shopify
            stellt uns die Online-E-Commerce-Plattform zur Verfügung, die es uns
            ermöglicht, unsere Produkte und Dienstleistungen an Sie zu
            verkaufen. ABSCHNITT 1 - BEDINGUNGEN FÜR DEN ONLINESHOP Mit der
            Zustimmung zu diesen Allgemeinen Geschäftsbedingungen erklären Sie,
            dass Sie in dem Land Ihres Wohnsitzes mindestens volljährig sind
            oder dass Sie in dem Land Ihres Wohnsitz volljährig sind und uns
            Ihre Zustimmung gegeben haben, dass Ihre minderjährigen Angehörigen
            diese Website nutzen dürfen. Sie dürfen unsere Produkte weder für
            illegale oder nicht autorisierte Zwecke nutzen noch dürfen Sie durch
            die Nutzung der Serviceleistung gegen Gesetze in Ihrer
            Gerichtsbarkeit verstoßen (einschließlich, aber nicht beschränkt auf
            Urheberrechtsgesetze). Sie dürfen keine Würmer oder Viren oder
            sonstigen Code destruktiver Art übertragen. Der Verstoß gegen oder
            die Verletzung irgendeiner dieser Bedingungen führt zur sofortigen
            Kündigung Ihrer Serviceleistungen. ABSCHNITT 2 - ALLGEMEINE
            BEDINGUNGEN Wir behalten uns das Recht vor, einer Person die
            Serviceleistung jederzeit aus beliebigem Grund zu verweigern. Sie
            nehmen zur Kenntnis, dass Ihre Informationen (außer
            Kreditkarteninformationen) unverschlüsselt übertragen werden können
            und (a) Übertragungen über verschiedene Netzwerke sowie (b)
            Änderungen beinhalten können, um den technischen Anforderungen von
            verbundenen Netzwerken oder Geräten zu entsprechen und sich an diese
            anzupassen. Kreditkarteninformationen werden bei der Übertragung
            über Netzwerke immer verschlüsselt. Sie verpflichten sich, ohne
            ausdrückliche schriftliche Genehmigung von uns keinen Teil der
            Serviceleistung, die Nutzung der Serviceleistung oder den Zugriff
            auf die Serviceleistung oder auf jegliche Kontakte auf der Website,
            durch die die Serviceleistung angeboten wird, zu reproduzieren, zu
            vervielfältigen, zu kopieren, zu verkaufen, weiterzuverkaufen oder
            zu verwerten. Die in dieser Vereinbarung verwendeten Überschriften
            dienen nur der Übersichtlichkeit und schränken diese Bedingungen
            nicht ein oder wirken sich anderweitig auf sie aus. ABSCHNITT 3 -
            GENAUIGKEIT, VOLLSTÄNDIGKEIT UND RECHTZEITIGKEIT DER INFORMATIONEN
            Wir sind nicht verantwortlich, wenn die auf dieser Seite zur
            Verfügung gestellten Informationen nicht genau, vollständig oder
            aktuell sind. Das Material auf dieser Website dient nur der
            allgemeinen Information und sollte nicht als alleinige Grundlage für
            Entscheidungen herangezogen werden, ohne primäre, genauere,
            vollständigere oder aktuellere Informationsquellen zu prüfen.
            Jegliches Vertrauen in das Material auf dieser Website geschieht auf
            eigene Verantwortung. Diese Seite enthält möglicherweise gewisse
            historische Informationen. Historische Informationen sind nicht
            unbedingt aktuell und werden lediglich zu Ihrer Orientierung
            bereitgestellt. Wir behalten uns das Recht vor, Inhalte auf dieser
            Website jederzeit zu ändern, sind aber nicht verpflichtet,
            irgendwelche Informationen auf unserer Website zu aktualisieren. Sie
            stimmen zu, dass Sie verantwortlich dafür sind, Änderungen auf
            unserer Website zu überwachen. ABSCHNITT 4 - ÄNDERUNGEN AN DER
            LEISTUNG UND DEN PREISEN Die Preise für unsere Produkte können ohne
            vorherige Ankündigung geändert werden. Wir behalten uns das Recht
            vor, die Serviceleistung (oder einen jeglichen Teil oder Inhalt
            davon) ohne Ankündigung jederzeit zu ändern oder zu beenden. Shopify
            ist weder Ihnen noch Dritten gegenüber haftbar für Änderungen,
            Preisänderungen, Sperrung oder Einstellung des Serviceleistung.
            ABSCHNITT 5 - PRODUKTE ODER SERVICELEISTUNGEN (falls zutreffend)
            Gewisse Produkte oder Serviceleistungen sind möglicherweise nur
            online über die Website erhältlich. Diese Produkte oder
            Serviceleistungen sind unter Umständen nur in begrenzten Mengen
            vorhanden und unterliegen nur der Rückgabe oder dem Umtausch gemäß
            unseren Rückgaberichtlinien. Wir haben uns bemüht, die Farben und
            Bilder unserer Produkte, die im Shop erscheinen, so genau wie
            möglich darzustellen. Wir können nicht garantieren, dass Ihr
            Computerbildschirm jede Farbe korrekt anzeigt. Wir behalten uns das
            Recht vor, sind aber nicht verpflichtet, den Verkauf unserer
            Produkte oder Serviceleistungen auf gewisse Personen, geografische
            Regionen oder Gerichtsbarkeiten zu beschränken. Wir können dieses
            Recht von Fall zu Fall ausüben. Wir behalten uns das Recht vor, die
            Mengen der von uns angebotenen Produkte oder Serviceleistungen zu
            begrenzen. Alle Produktbeschreibungen oder Produktpreise können
            jederzeit ohne Vorankündigung und nach unserem alleinigen Ermessen
            geändert werden. Wir behalten uns das Recht vor, jedes Produkt
            jederzeit aus dem Angebot zu nehmen. Jedes Angebot für ein Produkt
            oder eine Serviceleistung, das auf dieser Website gemacht wird, ist
            ungültig, wo es verboten ist. Wir garantieren nicht, dass die
            Qualität von Produkten, Serviceleistungen, Informationen oder
            anderen Materialien, die von Ihnen gekauft oder erworben wurden,
            Ihren Erwartungen entspricht, oder dass Fehler in der
            Serviceleistung korrigiert werden. ABSCHNITT 6 - RICHTIGKEIT DER
            RECHNUNGS- UND KONTOINFORMATIONEN Wir behalten uns das Recht vor,
            jegliche Bestellung, die Sie bei uns aufgeben, abzulehnen. Wir
            können nach eigenem Ermessen die Abnahmemengen pro Person, pro
            Haushalt oder pro Bestellung begrenzen oder stornieren. Diese
            Einschränkungen können Bestellungen umfassen, die von oder unter
            demselben Kundenkonto oder derselben Kreditkarte aufgegeben wurden
            und/oder Bestellungen, die dieselbe Rechnungs- und/oder
            Lieferadresse verwenden. Falls wir eine Bestellung ändern oder
            stornieren, werden wir versuchen, Sie zu benachrichtigen, indem wir
            die E-Mail und/oder die Rechnungsadresse/Telefonnummer kontaktieren,
            die zum Zeitpunkt der Bestellung angegeben wurde. Wir behalten uns
            das Recht vor, Bestellungen einzuschränken oder zu verbieten, die
            nach unserem alleinigen Ermessen den Anschein erwecken, von
            Händlern, Wiederverkäufern oder Distributoren aufgegeben worden zu
            sein. Sie stimmen zu, aktuelle, vollständige und richtige Kauf- und
            Kontoinformationen für alle in unserem Shop getätigten Käufe
            anzugeben. Sie stimmen zu, Ihr Konto und andere Informationen,
            einschließlich Ihrer E-Mail-Adresse und Kreditkartennummern und
            Ablaufdaten, umgehend zu aktualisieren, damit wir Ihre Transaktionen
            abschließen und Sie bei Bedarf kontaktieren können. Für weitere
            Details lesen Sie bitte unsere Rückgaberichtlinien. ABSCHNITT 7 -
            ZUSÄTZLICHE TOOLS Wir bieten Ihnen möglicherweise Zugriff auf Tools
            von Drittanbietern, die wir weder überwachen noch kontrollieren oder
            beeinflussen können. Sie stimmen zu, dass wir den Zugriff auf diese
            Tools "wie besehen" und "wie verfügbar" ohne jegliche Garantien,
            Zusicherungen oder Bedingungen jeglicher Art und ohne jegliche
            Befürwortung zur Verfügung stellen. Wir übernehmen keinerlei
            Haftung, die sich aus Ihrer Nutzung von zusätzlichen
            Drittanbieter-Tools ergibt oder damit zusammenhängt. Jegliche
            Nutzung von zusätzlichen Tools, die über die Website angeboten
            werden, erfolgt auf eigene Verantwortung und nach eigenem Ermessen,
            und Sie sollten sicherstellen, dass Sie mit den Bedingungen, zu
            denen die Tools von dem/den jeweiligen Drittanbieter/n zur Verfügung
            gestellt werden, vertraut sind und ihnen zustimmen. Wir werden
            möglicherweise in Zukunft auch neue Serviceleistungen und/oder
            Funktionen über die Website anbieten (einschließlich der
            Veröffentlichung neuer Tools und Ressourcen). Solche neuen
            Funktionen und/oder Serviceleistungen unterliegen ebenfalls diesen
            Allgemeinen Geschäftsbedingungen. ABSCHNITT 8 - DRITTANBIETER-LINKS
            Gewisse Inhalte, Produkte und Serviceleistungen, die über unseren
            Service erhältlich sind, können Materialien von Drittanbietern
            umfassen. Es kann sein, dass Drittanbieter-Links auf dieser Website
            Sie zu Drittanbieter-Websites führen, die nicht mit uns
            zusammenarbeiten. Wir sind nicht verantwortlich für die Prüfung oder
            Bewertung des Inhalts oder seiner Richtigkeit und wir übernehmen
            keine Garantie und keine Haftung oder Verantwortung für Materialien
            oder Websites oder für andere Materialien, Produkte oder
            Serviceleistungen von Drittanbietern. Wir haften nicht für Schäden,
            die im Zusammenhang mit dem Kauf oder der Benutzung von Waren,
            Serviceleistungen, Ressourcen, Inhalten oder anderen Transaktionen
            in Verbindung mit Drittanbieter-Websites entstehen. Bitte lesen Sie
            die Richtlinien und Praktiken des Drittanbieters sorgfältig durch
            und stellen Sie sicher, dass Sie diese verstehen, bevor Sie eine
            Transaktion durchführen. Reklamationen, Ansprüche, Bedenken oder
            Fragen zu Produkten von Drittanbietern sollten an den Drittanbieter
            gerichtet werden. ABSCHNITT 9 - BENUTZERKOMMENTARE, FEEDBACK UND
            ANDERE EINSENDUNGEN Wenn Sie auf unsere Aufforderung hin bestimmte
            Einsendungen (z. B. Wettbewerbsbeiträge) oder ohne Aufforderung
            durch uns kreative Ideen, Anregungen, Vorschläge, Pläne oder andere
            Materialien online, per E-Mail, per Post oder auf andere Weise
            (zusammenfassend als "Kommentare" bezeichnet) einsenden, erklären
            Sie sich damit einverstanden, dass wir Kommentare, die Sie an uns
            weiterleiten, jederzeit und ohne Einschränkung bearbeiten,
            vervielfältigen, veröffentlichen, verbreiten, übersetzen und auf
            andere Weise in einem beliebigen Medium verwenden dürfen. Wir sind
            und werden nicht verpflichtet sein, (1) Kommentare vertraulich zu
            behandeln, (2) eine Vergütung für Kommentare zu zahlen oder (3) auf
            Kommentare zu reagieren. Wir können Inhalte, die wir nach eigenem
            Ermessen als rechtswidrig, beleidigend, bedrohend, verleumderisch,
            diffamierend, pornografisch, obszön oder anderweitig anstößig oder
            als das geistige Eigentum irgendeiner Partei oder diese Allgemeinen
            Geschäftsbedingungen verletzend einstufen, überwachen, bearbeiten
            oder entfernen, sind aber nicht dazu verpflichtet. Sie stimmen zu,
            dass Ihre Kommentare keine Rechte Dritter verletzen, unter anderem
            Urheber-, Marken-, Datenschutz- oder Persönlichkeitsrechte oder
            andere persönliche Rechte oder Eigentumsrechte. Sie stimmen ferner
            zu, dass Ihre Kommentare kein verleumderisches, diffamierendes oder
            anderweitig rechtswidriges, beleidigendes, hasserfülltes oder
            obszönes Material oder Computerviren oder andere Malware enthalten,
            die den Betrieb der Services oder einer zugehörigen Website oder
            Anwendung in irgendeiner Weise beeinträchtigen könnten. Sie dürfen
            keine falsche E-Mail-Adresse verwenden, sich als eine andere Person
            ausgeben oder uns oder Dritte in Bezug auf die Herkunft von
            Kommentaren in die Irre führen. Für Ihre Kommentare und deren
            Richtigkeit tragen Sie die alleinige Verantwortung. Für Kommentare,
            die von Ihnen oder Dritten veröffentlicht wurden, sind wir weder
            verantwortlich noch haftbar. ABSCHNITT 10 - PERSONENBEZOGENE DATEN
            Die Übermittlung von personenbezogenen Daten über den Shop
            unterliegt unserer Datenschutzerklärung. Lesen Sie dafür unsere
            Datenschutzerklärung. ABSCHNITT 11 - FEHLER, UNGENAUIGKEITEN UND
            AUSLASSUNGEN Gelegentlich kann es vorkommen, dass unsere Website
            oder unsere Serviceleistung typografische Fehler, Ungenauigkeiten
            oder Auslassungen in Bezug auf Produktbeschreibungen,
            Preisgestaltung, Werbeaktionen, Angebote, Produktversandgebühr,
            Lieferzeit und Verfügbarkeit enthalten. Wir behalten uns das Recht
            vor, jederzeit und ohne vorherige Ankündigung (auch nachdem Sie Ihre
            Bestellung aufgegeben haben) jegliche Fehler, Ungenauigkeiten und
            Auslassungen zu korrigieren und Informationen zu ändern oder zu
            aktualisieren oder Bestellungen zu stornieren, wenn eine Information
            in Bezug auf die Serviceleistung oder auf irgendeiner zugehörigen
            Website falsch ist. Wir übernehmen keine Verpflichtung zur
            Aktualisierung, Änderung oder Klarstellung von Informationen in
            Bezug auf die Serviceleistung oder auf irgendeiner zugehörigen
            Website, einschließlich, aber nicht beschränkt auf,
            Preisinformationen, es sei denn, dies ist gesetzlich vorgeschrieben.
            Angeführte Aktualisierungsdaten, die in Bezug auf die
            Serviceleistung oder auf irgendeiner zugehörigen Website angegeben
            werden, sollten nicht als Hinweis darauf verstanden werden, dass
            alle Informationen in Bezug auf die Serviceleistung oder auf
            irgendeiner zugehörigen Website geändert oder aktualisiert wurden.
            ABSCHNITT 12 - VERBOTENE ANWENDUNGEN Zusätzlich zu anderen Verboten,
            die in den Allgemeinen Geschäftsbedingungen dargelegt sind, ist es
            Ihnen untersagt, die Website oder ihren Inhalt für Folgendes zu
            nutzen:(a) für rechtswidrige Zwecke; (b) um andere zur Durchführung
            oder Teilnahme an ungesetzlichen Handlungen aufzufordern; (c) um
            gegen internationale, bundesstaatliche, provinzielle oder staatliche
            Vorschriften, Regeln, Gesetze oder örtliche Verordnungen zu
            verstoßen; (d) um unsere Rechte an geistigem Eigentum oder die
            Rechte an geistigem Eigentum anderer zu beeinträchtigen oder zu
            verletzen; (e) um zu belästigen, zu missbrauchen, zu beleidigen, zu
            verletzen, zu diffamieren, zu verleumden, zu verunglimpfen,
            einzuschüchtern oder aufgrund von Geschlecht, sexueller
            Orientierung, Religion, ethnischer Zugehörigkeit, Rasse, Alter,
            nationaler Herkunft oder Behinderung zu diskriminieren; (f) falsche
            oder irreführende Informationen zu übermitteln; (g) um Viren oder
            jede andere Art von schädlichem Code hochzuladen oder zu übertragen,
            die auf irgendeine Weise die Funktionalität oder den Betrieb der
            Serviceleistung oder irgendeiner zugehörigen Website, anderer
            Websites oder des Internets beeinträchtigen; (h) um persönliche
            Daten anderer zu sammeln oder zu verfolgen; (i) zum Spammen,
            Phishing, Pharming, Pretexting, Spidering, Crawling oder Scraping;
            (j) für irgendwelche obszönen oder unmoralischen Zwecke; oder (k) um
            die Sicherheitsfunktionen der Serviceleistung oder irgendeiner
            zugehörigen Website, anderer Websites oder des Internets zu stören
            oder zu umgehen. Wir behalten uns das Recht vor, Ihre Nutzung der
            Serviceleistung oder irgendeiner zugehörigen Website zu beenden,
            wenn Sie eine der verbotenen Anwendungen missachten. ABSCHNITT 13 -
            HAFTUNGSAUSSCHLUSS; HAFTUNGSBESCHRÄNKUNG Wir übernehmen keine
            Garantie, Verantwortung oder Gewährleistung dafür, dass die Nutzung
            unserer Serviceleistung ununterbrochen, zeitgerecht, sicher oder
            fehlerfrei erfolgt. Wir garantieren nicht, dass die Ergebnisse, die
            durch die Nutzung der Serviceleistung erzielt werden können, genau
            oder zuverlässig sind. Sie stimmen zu, dass wir von Zeit zu Zeit die
            Serviceleistung auf unbestimmte Zeit entfernen oder jederzeit ohne
            Benachrichtigung an Sie einstellen können. Sie stimmen ausdrücklich
            zu, dass die Nutzung der Serviceleistung bzw. die Unfähigkeit zur
            Nutzung der Servicedienstleistung auf Ihr alleiniges Risiko erfolgt.
            Der Service und alle Produkte und Serviceleistungen, die Ihnen über
            den Service angeboten werden Ihnen (sofern nicht ausdrücklich von
            uns angegeben) "wie besehen" und "wie verfügbar" zur Ihrer Nutzung
            bereitgestellt, und zwar ohne jegliche Zusicherungen,
            Gewährleistungen oder Bedingungen jeglicher Art, weder ausdrücklich
            noch stillschweigend, einschließlich aller stillschweigenden
            Gewährleistungen oder Bedingungen der Handelsüblichkeit,
            handelsüblichen Qualität, Eignung für einen bestimmten Zweck,
            Belastbarkeit, Rechtsmängelhaftung oder Nichtverletzung von Rechten
            Dritter. Auf keinen Fall sind Keks Fabrik, unsere Direktoren,
            leitenden Angestellten, Mitarbeiter, Tochtergesellschaften,
            Vertreter, Auftragnehmer, Praktikanten, Lieferanten, Dienstanbieter
            oder Lizenzgeber haftbar für irgendwelche Verletzungen, Verluste,
            Ansprüche oder jegliche direkte, indirekte, zufällige, strafende,
            besondere oder Folgeschäden jeglicher Art, einschließlich, aber
            nicht beschränkt auf entgangene Gewinne, entgangene Einnahmen,
            verlorene Einsparungen, Datenverlust, Wiederbeschaffungskosten oder
            ähnliche Schäden, unabhängig davon, ob sie auf einem Vertrag, einer
            unerlaubten Handlung (einschließlich Fahrlässigkeit) oder
            verschuldensunabhängigen Haftung beruhen oder sich sonstwie aus der
            Nutzung des Dienstes oder von Produkten ergeben, die über den Dienst
            beschafft wurden, oder für andere Ansprüche, die in irgendeiner
            Weise mit der Nutzung des Dienstes oder eines Produkts
            zusammenhängen, einschließlich, aber nicht beschränkt auf Fehler
            oder Auslassungen in Inhalten oder Verluste oder Schäden jeglicher
            Art, die sich aus der Nutzung des Dienstes oder von Inhalten (oder
            Produkten) ergeben, die über den Dienst veröffentlicht, übertragen
            oder anderweitig zur Verfügung gestellt wurden, selbst wenn auf
            deren Möglichkeit hingewiesen wurde. Da einige Länder oder
            Gerichtsbarkeiten den Ausschluss oder die Haftungsbeschränkung für
            Folgeschäden oder zufällige Schäden in diesen Staaten oder
            Gerichtsbarkeiten nicht zulassen, ist unsere Haftung auf das
            gesetzlich maximal zulässige Maß beschränkt. ABSCHNITT 14 -
            ENTSCHÄDIGUNG Sie verpflichten sich, Keks Fabrik und unsere
            Muttergesellschaft, Tochtergesellschaften, verbundenen Unternehmen,
            Partner, leitenden Angestellten, Führungskräfte, Vertreter,
            Auftragnehmer, Lizenzgeber, Dienstleister, Subunternehmer,
            Lieferanten, Praktikanten und Mitarbeiter im Zusammenhang mit
            Ansprüchen oder Forderungen, einschließlich angemessener
            Anwaltskosten, die von Dritten aufgrund Ihrer Verletzung dieser
            Allgemeinen Geschäftsbedingungen oder der Dokumente, die durch
            Bezugnahme darauf Bestandteil derselben sind, oder Ihrer Verletzung
            eines Gesetzes oder der Rechte eines Dritten geltend gemacht werden,
            zu entschädigen, zu verteidigen und schadlos zu halten. ABSCHNITT 15
            - SALVATORISCHE KLAUSEL Sollte sich herausstellen, dass eine
            Bestimmung dieser Allgemeinen Geschäftsbedingungen rechtswidrig,
            nichtig oder nicht durchsetzbar ist, ist diese Bestimmung dennoch im
            gesetzlich zugelassenen Umfang durchsetzbar, und der nicht
            durchsetzbare Teil gilt als von diesen Allgemeinen
            Geschäftsbedingungen getrennt, wobei eine solche Festlegung keine
            Auswirkungen auf die Gültigkeit und Durchsetzbarkeit der übrigen
            Bestimmungen hat. ABSCHNITT 16 - KÜNDIGUNG Die Verpflichtungen und
            Verbindlichkeiten der Parteien, die vor dem Kündigungsdatum
            entstanden sind, überdauern die Kündigung dieser Vereinbarung für
            alle Zwecke. Diese Allgemeinen Geschäftsbedingungen sind so lange
            gültig, bis sie von Ihnen oder uns gekündigt werden. Sie können
            diese Allgemeinen Geschäftsbedingungen jederzeit kündigen, indem Sie
            uns mitteilen, dass Sie unsere Dienste nicht mehr nutzen möchten,
            oder wenn Sie die Nutzung unserer Website einstellen. Wenn Sie es
            nach unserem alleinigen Ermessen versäumen, irgendeine Bestimmung
            dieser Allgemeinen Geschäftsbedingungen zu erfüllen, oder wir dies
            vermuten, können wir diese Vereinbarung jederzeit fristlos kündigen,
            und Sie bleiben für alle fälligen Beträge bis einschließlich
            Kündigungsdatum haftbar, und/oder wir verweigern Ihnen den Zugang zu
            unseren Serviceleistungen (oder Teilen davon). ABSCHNITT 17 -
            GESAMTE VEREINBARUNG Das Versäumnis unsererseits, ein Recht oder
            eine Bestimmung dieser Allgemeinen Geschäftsbedingungen auszuüben
            oder durchzusetzen, stellt keinen Verzicht auf dieses Recht oder
            diese Bestimmung dar. Diese Allgemeinen Geschäftsbedingungen sowie
            von uns auf dieser Website oder in Bezug auf die Serviceleistung
            veröffentlichten Richtlinien oder Betriebsvorschriften, stellen die
            gesamte Vereinbarung und Übereinkunft zwischen Ihnen und uns dar,
            regeln Ihre Nutzung der Serviceleistung und ersetzen alle vorherigen
            oder gleichzeitigen Vereinbarungen, Mitteilungen und Angebote,
            mündlich oder schriftlich, zwischen Ihnen und uns (einschließlich,
            aber nicht beschränkt auf vorherige Versionen der Allgemeinen
            Geschäftsbedingungen). Etwaige Unklarheiten bei der Auslegung dieser
            Allgemeinen Geschäftsbedingungen sind nicht gegen die verfassende
            Partei zu verstehen. ABSCHNITT 18 - GELTENDES RECHT Diese
            Allgemeinen Geschäftsbedingungen und alle separaten Vereinbarungen,
            in deren Rahmen wir Ihnen Serviceleistungen zur Verfügung stellen,
            unterliegen den Gesetzen von Danzinger Platz 2-4, Frankfurt am Main
            , Hessen, 60314, Deutschland und sind nach diesen auszulegen.
            ABSCHNITT 19 – ÄNDERUNGEN DER ALLGEMEINEN GESCHÄFTSBEDINGUNGEN Die
            aktuellste Version der Allgemeinen Geschäftsbedingungen können Sie
            jederzeit auf dieser Seite einsehen. Wir behalten uns das Recht vor,
            jegliche Teile dieser Allgemeinen Geschäftsbedingungen nach eigenem
            Ermessen zu aktualisieren, zu ändern oder zu ersetzen, indem wir
            Aktualisierungen und Änderungen auf unserer Website veröffentlichen.
            Es liegt in Ihrer Verantwortung, unsere Website regelmäßig auf
            mögliche Änderungen zu überprüfen. Indem Sie unsere Website oder die
            Serviceleistung nach der Veröffentlichung von Änderungen dieser
            Allgemeinen Geschäftsbedingungen weiterhin nutzen oder weiterhin
            darauf zugreifen, gilt dies als Annahme dieser Änderungen. ABSCHNITT
            20 – KONTAKTDATEN Fragen zu den Allgemeinen Geschäftsbedingungen
            sind per Post an uns zu richten.
          </p>
        </div>
      </section>
    </React.Fragment>
  );
  //}
};

export default Ag;
