import HeaderBar from "./headerBar";
import Food from "./food";
import React from "react";

const Startpage = () => {
  return (
    <div className="home__content__area">
      <HeaderBar></HeaderBar>
      {/*       <section className="orderArea"></section> */}
      <section className="foodArea">
        <Food></Food>
      </section>
    </div>
  );
};

export default Startpage;
