import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/ShoppingBottomBar.css";
import Badge from "./badge";
/* import axios from "axios";

import { API_URL } from "../constants/index"; */

//class BottomBar extends Component {
const ShoppingBottomBar = ({ value, folgCountProp, folgTotalProp }) => {
  /*   const [folgTotal, setFolgTotal] = useState();
  const [folgCount, setFolgCount] = useState([]); */
  const [windowLoc, setWindowLoc] = useState();
  /*   const [bCompVisible, setCompVisible] = useState(false); */

  useEffect(() => {
    let sTestString = "shoppingcart";
    let sPathName = window.location.pathname;
    if (sPathName.indexOf(sTestString) >= 0) {
      setWindowLoc(sTestString);
    } else {
      setWindowLoc("home");
    }
    /*     const getFolgCount = () => {
      let cartId = localStorage.getItem("cartId");
      if (cartId) {
        axios.get(API_URL + "basketcount/" + cartId + "/").then((res) => {
          setFolgCount(res.data);
          if (res.data !== 0) {
            setCompVisible(true);
          }
        });
      }
    };

    const getFolgSum = () => {
      let cartId = localStorage.getItem("cartId");
      if (cartId) {
        axios
          .get(API_URL + "folgTotal/" + cartId + "/")
          .then((res) => setFolgTotal(res.data));
      }
    };
    getFolgCount();
    getFolgSum(); */
  }, [folgCountProp, folgTotalProp]);
  const navigate = useNavigate();

  const goToCheckout = () => {
    let folgId = localStorage.getItem("cartId");
    navigate("/checkout/" + folgId);
  };

  const goToCart = () => {
    let folgId = localStorage.getItem("cartId");
    navigate("/shoppingcart/" + folgId);
  };

  const onAddClicked = () => {
    if (windowLoc === "home") {
      goToCart();
    } else {
      goToCheckout();
    }
  };

  return (
    <>
      <footer id="shoppingCartBottomBar" className="shopping__bottom__bar">
        <div className="grid--1x2">
          <div className="bottom__bag">
            {/* <div className="yellow__badge">{props.folgCount}</div> */}
            {folgCountProp !== 0 ? (
              <Badge folgCount={folgCountProp}></Badge>
            ) : null}

            {/*           <img src="/assets/img/Bag.png" alt="Eine Einkaufstasche" /> */}
          </div>
          <div className="bottom__text__shoppingcart">
            {/* TODO display shopping cart items and total sum is this possible  */}
            <div>
              <span>Deine Bestellung</span>
              <span className="bottom__text__right">
                {/*      {parseFloat(folgTotal).toFixed(2)} € */}
                {folgTotalProp ? (
                  <>{parseFloat(folgTotalProp).toFixed(2)} €</>
                ) : null}
              </span>
            </div>

            <div>
              <strong>Summe: </strong>
              <span className="bottom__text__right">
                {folgTotalProp ? (
                  <>{parseFloat(folgTotalProp).toFixed(2)} €</>
                ) : null}
              </span>
            </div>
          </div>
        </div>
        {folgCountProp !== 0 ? (
          <div className="order__btn__area">
            <button onClick={() => onAddClicked()} className="btn btn--yellow">
              <div>
                {windowLoc === "home" ? (
                  <span>Deine Bestellung</span>
                ) : (
                  <span>Jetzt Bestellen</span>
                )}

                <img
                  className="btn__arrow__right"
                  src="/assets/img/arrow_black.png"
                  alt="Ein Pfeil nach Rechts"
                />
              </div>
            </button>
          </div>
        ) : null}
      </footer>
    </>
  );
};

export default ShoppingBottomBar;
