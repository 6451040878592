import React, { useState, useEffect } from "react";
import { IMG_URL } from "../constants";

/* import { useHistory } from "react-router-dom"; */
/* import axios from "axios";
import { API_URL } from "../constants/index";
import { useNavigate } from "react-router-dom"; */

const Card = ({ updateFolg, ...props }) => {
  /*   const navigate = useNavigate(); */
  const [selectedNugget, setSelectedNugget] = useState({});

  useEffect(() => {}, []);

  const onNavigateToDetail = (selectedNug) => {
    let nugget;
    if (Object.entries(selectedNugget).length > 0) {
      nugget = selectedNugget;
    } else {
      nugget = selectedNug;
    }
    if (nugget === undefined) {
      nugget = { pk: localStorage.getItem("nupk"), menge: 1 };
    }
    updateFolg({
      nugget_id: nugget.pk,
      description: nugget.description,
      einzelpreis: nugget.einzelpreis,
      menge: nugget.menge,
    });
  };

  /*   const createOrUpdateFolg = (nugget) => {
    let cartId = localStorage.getItem("cartId");
    axios
      .post(API_URL + "folg/", { nugget: nugget, cartId: cartId })
      .then((data) => {
        if (data.data.cartId) {
          localStorage.setItem("cartId", data.data.cartId);
        }
      });
  }; */

  const onAddClicked = (selectedNugget) => {
    localStorage.setItem("nupk", selectedNugget.pk);
    setSelectedNugget(selectedNugget);
    onNavigateToDetail(selectedNugget);
    //props.updateFolg(selectedNugget);
  };

  return (
    <React.Fragment>
      <div className="card" onClick={() => onAddClicked(props.nugget)}>
        <header className="card__header">
          <div className="food__img">
            <img
              className="img__circle"
              src={IMG_URL + props.nugget.post__image}
              alt="Essen"
            />
          </div>
        </header>
        <div className="card__body">
          <div className="card__body__heading">
            <h4>{parseFloat(props.nugget.einzelpreis).toFixed(2)} €</h4>
            <h3>{props.nugget.description}</h3>
            <p>{props.nugget.description_long}</p>
          </div>

          <div className="card__body__footing">
            <span className="btn btn--beet-root">Hinzufügen</span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Card;
