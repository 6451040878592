import React, { useEffect } from "react";
import Headerlogo from "../logo.svg";

const Datenschutz = () => {
  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <a className="btn btn__close blue__bg" href="/">
        Schließen
      </a>

      {/*       <div id="contactBtn" className="contact__btn__fixed">
        <a href="/#contact" className="btn btn__green">
          Kontakt
        </a>
      </div> */}

      <section className="block__ds">
        <div className="btn--header">
          {/*             <a href="#" className="btn btn--secondary">
            Kontakt
          </a> */}
        </div>
        <div className="block__div__img">
          <img
            className="block__img"
            src={Headerlogo}
            alt="Logo of the Company"
          />
        </div>
        <div className="ds__text">
          <h4>Datenschutzerklärung</h4>
          <p>
            Keks Fabrik Datenschutzerklärung In dieser Datenschutzerklärung wird
            beschrieben, wie Ihre personenbezogenen Daten erfasst, verwendet und
            weitergegeben werden, wenn Sie www.keksfabrik.de (die "Website")
            nutzen oder dort etwas kaufen. VON UNS ERFASSTE PERSONENBEZOGENE
            DATEN Wenn Sie die Website besuchen, erfassen wir automatisch
            bestimmte Informationen über Ihr Gerät, darunter Informationen zum
            Webbrowser, der IP-Adresse, der Zeitzone und einigen der Cookies,
            die auf Ihrem Gerät installiert sind. Wenn Sie auf der Website
            navigieren, erfassen wir außerdem Informationen zu den einzelnen
            Webseiten oder Produkten, die Sie aufrufen, zu den Websites oder
            Suchbegriffen, die Sie auf die Website geführt haben, sowie
            Informationen darüber, wie Sie mit der Website interagieren. Wir
            bezeichnen diese automatisch erfassten Informationen als
            "Geräteinformationen". Wir erfassen Geräteinformationen mithilfe der
            folgenden Technologien: - "Cookies" sind Datendateien, die auf einem
            Gerät oder Computer gespeichert werden und häufig eine anonyme
            eindeutige Kennung enthalten. Weitere Informationen zu Cookies und
            zum Deaktivieren von Cookies sind unter
            http://www.allaboutcookies.org zu finden. - "Protokolldateien"
            protokollieren Aktionen auf der Website und erfassen Daten wie
            IP-Adresse, Browsertyp, Internetdienstanbieter,
            verweisende/Ausstiegsseiten sowie Datums-/Uhrzeitstempel. - "Web
            Beacons", "Tags" und "Pixel" sind elektronische Dateien zur
            Erfassung von Informationen dazu, wie Sie auf der Website
            navigieren. [[BESCHREIBUNG ANDERER VERWENDETER
            PROTOKOLLIERUNGSTECHNOLOGIEN EINFÜGEN]] Wenn Sie auf der Website
            etwas kaufen oder versuchen, etwas zu kaufen, erfassen wir außerdem
            bestimmte Informationen über Ihre Person. Dazu gehören Name,
            Rechnungsadresse, Lieferadresse, Zahlungsinformationen
            (einschließlich Kreditkartennummern [[ANDERE ZULÄSSIGE ZAHLUNGSARTEN
            EINFÜGEN]]), E-Mail-Adresse und Telefonnummer. Wir bezeichnen diese
            Angaben als "Bestellinformationen". [[ANDERE ERFASSTE INFORMATIONEN
            EINFÜGEN: OFFLINE-DATEN, ERWORBENE MARKETINGDATEN/-LISTEN]] Mit
            "personenbezogene Daten" in dieser Datenschutzerklärung bezeichnen
            wir sowohl Geräteinformationen als auch Bestellinformationen. WIE
            VERWENDEN WIR IHRE PERSONENBEZOGENEN DATEN? Wir verwenden die von
            uns erfassten Bestellinformationen in der Regel zur Ausführung von
            Bestellungen, die wir über die Website erhalten (unter anderem zur
            Verarbeitung Ihrer Zahlungsinformationen, Versandbereitstellung und
            Zusendung von Rechnungen und/oder Bestellbestätigungen). Außerdem
            verwenden wir diese Bestellinformation zur: Kommunikation mit Ihnen;
            Prüfung unserer Bestellungen auf potenzielle Risiken oder Betrug und
            Bereitstellung von Informationen oder Werbung im Zusammenhang mit
            unseren Produkten oder Dienstleistungen an Sie, sofern dies mit
            Ihren Datenschutzeinstellungen im Einklang steht. [[ANDERE
            VERWENDUNGSZWECKE VON BESTELLINFORMATIONEN EINFÜGEN]] Wir verwenden
            die von uns erfassten Geräteinformationen für die Prüfung auf
            potenzielle Risiken und Betrug (insbesondere Ihre IP-Adresse) und
            allgemein zur Verbesserung und Optimierung unserer Website (z.B.
            mithilfe von Analysen zum Navigationsverhalten unserer Kunden und
            ihrer Interaktion mit der Website sowie zum Beurteilen des Erfolgs
            unserer Marketing- und Werbekampagnen). [[ANDERE VERWENDUNGSZWECKE
            VON GERÄTEINFORMATIONEN EINFÜGEN, DARUNTER: WERBUNG/RETARGETING]]
            WEITERGABE IHRER PERSONENBEZOGEN DATEN Wir geben Ihre
            personenbezogenen Daten an Dritte weiter, die uns bei der Verwendung
            Ihrer personenbezogenen Daten wie oben beschrieben unterstützen. Zum
            Beispiel verwenden wir Shopify, um unseren Onlineshop zu betreiben.
            Weitere Informationen dazu, wie Shopify Ihre personenbezogenen Daten
            verwendet, finden Sie hier: https://www.shopify.com/legal/privacy.
            Außerdem verwenden wir Google Analytics, um zu verstehen, wie unsere
            Kunden die Website nutzen. Weitere Informationen zur Verwendung
            Ihrer personenbezogenen Daten durch Google finden Sie hier:
            https://policies.google.com/privacy?hl=de. Hier können Sie Google
            Analytics deaktivieren: https://tools.google.com/dlpage/gaoptout.
            Schließlich können wir Ihre personenbezogenen Daten auch
            weitergeben, um geltende Gesetze und Vorschriften einzuhalten, um
            auf eine Vorladung, einen Durchsuchungsbefehl oder eine andere
            rechtmäßige Anfrage nach Informationen, die wir erhalten, zu
            reagieren oder um unsere Rechte anderweitig zu schützen. [[EINFÜGEN,
            WENN REMARKETING ODER ZIELGERICHTETE WERBUNG EINGESETZT WERDEN]]
            VERHALTENSBASIERTE WERBUNG Wie oben beschrieben verwenden wir Ihre
            personenbezogenen Daten, um Ihnen zielgerichtete Werbung oder
            Marketingmitteilungen zur Verfügung zu stellen, von denen wir
            glauben, dass sie für Sie von Interesse sein könnten. Weitere
            Informationen darüber, wie zielgerichtete Werbung funktioniert,
            finden Sie auf der Aufklärungsseite der Network Advertising
            Initiative ("NAI") unter
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
            Sie können zielgerichtete Anzeigen hier deaktivieren: [[
            OPT-OUT-LINKS VON DEN VERWENDETEN DIENSTEN EINFÜGEN.]] ZU DEN
            GÄNGIGEN LINKS GEHÖREN: FACEBOOK –
            https://www.facebook.com/settings/?tab=ads GOOGLE –
            https://www.google.com/settings/ads/anonymous BING –
            https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
            ]] Darüber hinaus können Sie einige dieser Dienste über das
            Opt-out-Portal der Digital Advertising Alliance deaktivieren:
            http://optout.aboutads.info/. DO NOT TRACK Wir weisen Sie darauf
            hin, dass wir die Datenerfassungs- und -nutzungsverfahren unserer
            Website nicht ändern, wenn wir von Ihrem Browser ein "Do Not
            Track"-Signal erhalten. [[EINFÜGEN, WENN SIE IN EUROPA SIND ODER IHR
            SHOP KUNDEN IN EUROPA HAT]] IHRE RECHTE Wenn Sie in Europa ansässig
            sind, haben Sie das Recht, auf die personenbezogenen Daten, die wir
            über Sie besitzen, zuzugreifen und deren Korrektur, Aktualisierung
            oder Löschung zu fordern. Wenn Sie dieses Recht ausüben möchten,
            setzen Sie sich über die nachstehenden Kontaktdaten mit uns in
            Verbindung. Wenn Sie in Europa ansässig sind, weisen wir außerdem
            darauf hin, dass wir Ihre Daten verarbeiten, um Verträge mit Ihnen
            zu erfüllen (z. B. wenn Sie über die Website eine Bestellung
            aufgeben) oder um unsere oben genannten berechtigten
            Geschäftsinteressen zu verfolgen. Bitte beachten Sie außerdem, dass
            Ihre Daten außerhalb von Europa, einschließlich Kanada und den
            Vereinigten Staaten, übertragen werden. AUFBEWAHRUNG VON DATEN Wenn
            Sie eine Bestellung über die Website aufgeben, bewahren wir Ihre
            Bestellinformationen für unsere Aufzeichnungen auf, sofern Sie uns
            nicht auffordern, diese Daten zu löschen. [EINFÜGEN, FALLS
            ALTERSBESCHRÄNKUNG NÖTIG]] MINDERJÄHRIGE Die Website ist nicht für
            Personen vorgesehen, die jünger als [[ALTER EINFÜGEN]] Jahre alt
            sind. ÄNDERUNGEN Wir können diese Datenschutzerklärung gelegentlich
            ändern, um Änderungen unserer Vorgehensweisen zu berücksichtigen,
            oder aus anderen betrieblichen, rechtlichen oder
            aufsichtsrechtlichen Gründen. KONTAKT Wenn Sie weitere Informationen
            zu unseren Datenschutzverfahren benötigen, Fragen haben oder eine
            Beschwerde einreichen möchten, kontaktieren Sie uns per Post an die
            unten genannte Adresse: Friedberger Landstraße 96, 60316 Frankfurt
            am Main
          </p>
        </div>
      </section>
    </React.Fragment>
  );
  //}
};

export default Datenschutz;
