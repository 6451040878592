import React from "react";
import Headerlogo from "../images/logo_new.jpg";
/* import Hello from "./hello";
 */
const HeaderBar = () => {
  return (
    <React.Fragment>
      <section className="block">
        {/*         <Hello></Hello> */}

        <div className="btn--header"></div>
        <div className="block__div__img">
          <img
            className="block__img"
            src={Headerlogo}
            alt="Logo of the Company"
          />
        </div>
      </section>
    </React.Fragment>
  );
  // }
};

export default HeaderBar;
