import React from "react";

const Error = ({ errors, inputType, ...props }) => {
  if (errors) {
    return <div className="error">{errors[inputType]}</div>;
  }
  return null;
};

const RemainingErrors = ({ errors, input, ...props }) => {
  if (errors) {
    return Object.keys(errors).map((type) => {
      if (!(type in input)) {
        return <Error errors={errors} inputType={type} />;
      }
      return null;
    });
  }
  return null;
};

const FormInput = ({ input, setInput, inputType, errors, ...props }) => {
  const determineInputType = (inputType) => {
    if (inputType === "password") {
      return "password";
    } else if (inputType === "email") {
      return "email";
    } else {
      return "text";
    }
  };
  const handleChange = (newValue) => {
    setInput({
      ...input,
      [inputType]: newValue,
    });
  };
  return (
    <tr>
      <td>{inputType}</td>
      <td>
        <input
          value={input[inputType]}
          type={determineInputType(inputType)}
          onChange={(e) => handleChange(e.target.value)}
        />
        <br />
      </td>
      <td>
        <Error errors={errors} inputType={inputType} />
      </td>
    </tr>
  );
};

const FormInputs = ({ input, setInput, errors, ...props }) => {
  return (
    <div className="FormInputs">
      <table>
        <tbody>
          <tr>
            <RemainingErrors errors={errors} input={input} />
          </tr>
          {Object.keys(input).map((type, key) => (
            <FormInput
              key={key}
              input={input}
              setInput={setInput}
              inputType={type}
              errors={errors}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FormInputs;
