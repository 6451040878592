import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
/* import CloseBtn from "./closeBtn"; */
/* import OrderBar from "./orderBar"; */
import ShoppingBottomBar from "./shoppingBottomBar";
/* import Method from "./method"; */

import "../css/ShoppingCart.css";
import axios from "axios";

import { API_URL } from "../constants/index";

const ShoppingCart = (props) => {
  const navigate = useNavigate();

  /* const [lieferung, setLieferung] = useState(true); */
  const [baskets, setBaskets] = useState([]);
  /*   const [bBarVisible, setBarVisibile] = useState(true); */
  const [folgCount, setFolgCount] = useState([]);
  const [folgTotal, setFolgTotal] = useState();

  const resetState = () => {
    getBaskets();
    //getCartCount();
    getFolgSum();
    getFolgCount();
    /*  let sLieferung = localStorage.getItem("lieferung"); */
    /*     let sLocation = localStorage.getItem("location");
    setLocation(sLocation); */
    /*    if (sLieferung === "true") {
      setLieferung(true);
    } else {
      setLieferung(false);
    } */
  };

  useEffect(() => {
    resetState();
  }, []);

  const getFolgCount = () => {
    let cartId = localStorage.getItem("cartId");
    if (cartId) {
      axios.get(API_URL + "basketcount/" + cartId + "/").then((res) => {
        setFolgCount(res.data);
      });
    }
  };

  const getFolgSum = () => {
    let cartId = localStorage.getItem("cartId");
    if (cartId) {
      axios
        .get(API_URL + "folgTotal/" + cartId + "/")
        .then((res) => setFolgTotal(res.data));
    }
  };

  const getBaskets = () => {
    let cartId = localStorage.getItem("cartId");
    if (cartId) {
      axios.get(API_URL + "baskets/" + cartId + "/").then((res) => {
        setBaskets(res.data);
      });
    }
  };

  const updateCart = (e, selectedBasket, bRemove) => {
    if (bRemove) {
      selectedBasket.menge--;
    } else {
      selectedBasket.menge++;
    }
    axios
      .put(API_URL + "editbasket/" + selectedBasket.pk + "/", {
        newMenge: selectedBasket.menge,
        removeFlag: bRemove,
      })
      .then((res) => {
        resetState();
      });
  };

  const deletefromBasket = (e, selectedBasket) => {
    axios
      .delete(API_URL + "editbasket/" + selectedBasket.pk + "/")
      .then((res) => {
        resetState();
      });
  };

  /*   const onEditBasket = (selectedBasket) => {
    navigate("/detail/" + selectedBasket.pk);
  };

  const createOrUpdateFolg = (nugget) => {
    let cartId = localStorage.getItem("cartId");
    axios
      .post(API_URL + "folg/", { nugget: nugget, cartId: cartId })
      .then((data) => {
        resetState();
      });
  };

  const goToCheckout = () => {
    let folgId = localStorage.getItem("cartId");
    navigate("/checkout/" + folgId);
  };

  const onInputBlurOrFocus = (bVisible) => {
    setBarVisibile(bVisible);
  };

  const onLieferungChanged = (bLieferung) => {
    setLieferung(bLieferung);
  }; */

  const onCancel = () => {
    navigate("/");
  };

  return (
    <div className="shoppingCart__content">
      {/*    <CloseBtn></CloseBtn> */}
      <div>
        <button
          onClick={() => {
            onCancel();
          }}
          className="chackout__cancel__btn"
        >
          weiter Einkaufen
        </button>
      </div>
      <div className="shoppingcart__content__area">
        <h2 className="heading__shoppingcart">Deine Bestellung</h2>

        {/*         <Method
          onInputChange={onInputBlurOrFocus}
          onMethodChange={onLieferungChanged}
        ></Method> */}

        <div className="shoopingcart__products">
          {baskets ? (
            baskets.map((curBasket) => (
              <div key={curBasket.pk} className="card__shoppingcart">
                {curBasket.nugget_id !== 42 ? (
                  <>
                    {curBasket.nugget__pic_url !== "" ? (
                      <header>
                        <div className="food__img__shoppingcart">
                          <img
                            className="shoppingcart_img"
                            src={"/" + curBasket.nugget__pic_url}
                            alt="Bild vom Essen"
                          />
                        </div>
                        {/* <img src={nugget.pic_url} alt="Logo of the Company" /> */}
                      </header>
                    ) : null}

                    <div className="card__body__shopping_cart">
                      <div className="card__body__heading">
                        <div className="shoopingcart__heading__price">
                          <h3>{curBasket.description}</h3>
                          <span>
                            {parseFloat(curBasket.value).toFixed(2)} €
                            {/*      {curBasket.value} € */}
                          </span>
                        </div>
                        {curBasket.addonString !== "" ? (
                          <>
                            {" "}
                            <p>Extras:</p>
                            {curBasket.addonString}
                          </>
                        ) : null}
                      </div>

                      <div className="btn-group">
                        {/*                         <button
                          onClick={() => onEditBasket(curBasket)}
                          className="btn--onion-white shoppingcart_btn_group"
                        >
                          <span className="shoppingcart_btn_group--edit">
                            Bearbeiten
                          </span>
                        </button> */}

                        <div className="shoppingcart__btns--plus__minus">
                          <button
                            className="shoppingcart__btn btn_minus"
                            onClick={(event) =>
                              updateCart(event, curBasket, true)
                            }
                          >
                            -
                          </button>
                          <span>{curBasket.menge}</span>
                          <button
                            className="shoppingcart__btn btn_plus"
                            onClick={(event) =>
                              updateCart(event, curBasket, false)
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                      <div className="btn">
                        <button
                          className="shoppingcart__item__del"
                          onClick={(event) =>
                            deletefromBasket(event, curBasket)
                          }
                        >
                          Entfernen
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            ))
          ) : (
            <p>Keine Produkte im Warenkorb</p>
          )}
        </div>

        <ShoppingBottomBar
          folgTotalProp={folgTotal}
          folgCountProp={folgCount}
        ></ShoppingBottomBar>
      </div>
    </div>
  );
};
//}

export default ShoppingCart;
