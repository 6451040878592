import http from "./httpService.js";
import { API_URL } from "./settings.js";

const tokenKey = "token";

export const register = async (user) => {
  const response = await http.post(API_URL + "users/create/", user);
  localStorage.setItem(tokenKey, response.data.token);
  localStorage.setItem("user", JSON.stringify(response.data));
  http.updateToken();
  return response;
};

export const login = async (loginDetails) => {
  const response = await http.post(API_URL + "login/", loginDetails);
  localStorage.setItem(tokenKey, response.data.token);
  localStorage.setItem("user", JSON.stringify(response.data));
  http.updateToken();
  return response;
};

export const logout = () => {
  localStorage.clear();
  window.location.reload(false);
};

export const getUser = async (setUser) => {
  const response = await http.get(API_URL + "api/users/");
  let users = await response.data;
  setUser([...users]);
};
