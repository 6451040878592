import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constants/index.js";
import Login from "./login.jsx";

import "../../css/FolgAdmin.css";

const FolgAdmin = (props) => {
  let navigate = useNavigate();
  const [user, setUser] = useState("");

  const getFolgs = () => {
    axios.get(API_URL + "folg/").then((res) => {
      setFolgs(res.data.orders);
    });
  };

  const [folgs, setFolgs] = useState([]);
  const [statis, setStatis] = useState([]);
  //const [orders, setOrders] = useState();
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    let orderCount = 0;
    let audio = new Audio(
      "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3"
    );
    const checkFolgs = () => {
      axios.get(API_URL + "folg/").then((res) => {
        if (orderCount !== 0 && res.data.active_count !== orderCount) {
          audio.play();
        }
        orderCount = res.data.active_count;
      });
    };
    //window.setInterval(checkFolgs, 6000);
    getFolgs();
    getStatis();
  }, []);

  /*   const play = () => {
    let audio = new Audio(
      "https://interactive-examples.mdn.mozilla.net/media/cc0-audio/t-rex-roar.mp3"
    );
    audio.play();
  }; */

  const getStatis = () => {
    axios.get(API_URL + "getStatis/").then((res) => {
      setStatis(res.data);
    });
  };

  const gotToFolgDetails = (folgPk) => {
    navigate("/status/" + folgPk);
  };

  const setCurrentStatus = (folgPk, statusId) => {
    axios
      .put(API_URL + "folgStatus/" + folgPk + "/", { newStatus: statusId })
      .then((res) => {
        getFolgs();
      });
  };

  const deleteFolg = (selectedFolgPk) => {
    axios.delete(API_URL + "deletefolg/" + selectedFolgPk + "/").then((res) => {
      getFolgs();
    });
  };

  const RegisterLogin = ({ setUser, ...props }) => {
    return (
      <>
        <Login setUser={setUser} />
      </>
    );
  };

  const generatePDF = (selectedFolgPk) => {
    window.open(API_URL + "basket_pdf/" + selectedFolgPk + "/");
  };

  const FolgsDetails = folgs.map((folg) => (
    <li className="admin__list__card" key={folg.folg__pk}>
      <button
        className="admin__btn btn__nav"
        onClick={() => gotToFolgDetails(folg.folg__pk)}
      >
        <span>Go To Details of {folg.folg__pk}</span>
      </button>
      <p>
        {"Letzte Änderung : "}
        {folg.folg__lastchanged}
        <span>
          {" "}
          Status: {folg.folg__status__description}
          <span className="admin__sum">Summe: {folg.folg_sum} €</span>
          <span>
            Kunde: {folg.folg__pair__namevorname} {folg.folg__pair__strassenr}{" "}
            {folg.folg__pair__plzstadt} Tel: {folg.folg__pair__telefonnr}
          </span>
          <span className="admin__sum">
            Payment Method : {folg.folg__method__description}
          </span>
        </span>
      </p>

      <p>
        {statis.map((status) => (
          <button
            className="admin__btn btn__status"
            key={status.id}
            onClick={() => setCurrentStatus(folg.folg__pk, status.id)}
          >
            <span>set Status {status.description}</span>
          </button>
        ))}
      </p>
      <span className="admin__sum">
        <button
          className="admin__btn btn__del"
          onClick={() => deleteFolg(folg.folg__pk)}
        >
          <span>Delete {folg.folg__pk}</span>
        </button>
      </span>

      {/*       <span className="admin__sum">
        <button
          className="admin__btn"
          onClick={() => generatePDF(folg.folg__pk)}
        >
          <span>Print {folg.folg__pk}</span>
        </button>
      </span> */}
    </li>
  ));
  return (
    <div>
      {user ? (
        <>
          <h1>Bestellungen</h1>
          <ul className="list">{FolgsDetails}</ul>
        </>
      ) : (
        <RegisterLogin setUser={setUser} />
      )}
    </div>
  );
};
//}

export default FolgAdmin;
