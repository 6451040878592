import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Startpage from "./startpage";
import ShoppingCart from "./shoppingCart";
import Checkout from "./checkout";
import Status from "./status";
import Login from "./admin/login";
import Products from "./admin/products";
import FolgAdmin from "./admin/folgAdmin";
import Datenschutz from "./datenschutz";
import Ag from "./ag";

const Home = () => {
  return (
    <BrowserRouter>
      {/*       <Header user={user} /> */}
      <Routes>
        <Route path="/" element={<Startpage />} />
        <Route path="/shoppingcart/:basketId" element={<ShoppingCart />} />
        <Route path="/checkout/:folgId" element={<Checkout />} />
        <Route path="/status/:folgId" element={<Status />} />
        <Route path="/login" element={<Login />} />
        <Route path="/products" element={<Products />} />
        <Route path="/keksorders" element={<FolgAdmin />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/ag" element={<Ag />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
