import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../constants";

const EditProductModal = ({
  setIsOpen,
  getProducts,
  selectedProduct,
  setSelectedProduct,
}) => {
  const [productDetails, setProductDetails] = useState({
    description: "",
    description_long: "",
    einzelpreis: 0,
    active: false,
    pic_url: "assets/img/",
  });
  const [manFieldSelected, setManFieldSelected] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    if (selectedProduct) {
      setProductDetails(selectedProduct);
    }
  }, [selectedProduct]);

  const onCreateProduct = () => {
    let form_data = new FormData();
    form_data.append("image", image, image.name);
    form_data.append("description", productDetails.description);
    form_data.append("description_long", productDetails.description_long);
    form_data.append("einzelpreis", productDetails.einzelpreis);
    form_data.append("active", productDetails.active);
    axios.post(API_URL + "posts/", form_data).then((res) => {
      getProducts();
      setIsOpen(false);
      setSelectedProduct(false);
    });
  };

  const onProductChanged = (e) => {
    let newValue = e.target.value;
    let curName = e.target.id;
    if (curName === "active") {
      newValue = e.target.checked;
    }
    setProductDetails({ ...productDetails, [curName]: newValue });
    setManFieldSelected(true);
    // set Product Detaisl
  };

  const onCloseModal = () => {
    setSelectedProduct(false);
    setIsOpen(false);
  };

  const onSaveProduct = () => {
    debugger;
    let form_data = new FormData();
    if (image) {
      form_data.append("image", image, image.name);
    }
    form_data.append("id", productDetails.pk);
    form_data.append("description", productDetails.description);
    form_data.append("description_long", productDetails.description_long);
    form_data.append("einzelpreis", productDetails.einzelpreis);
    form_data.append("active", productDetails.active);
    axios.put(API_URL + "posts/", form_data).then((res) => {
      getProducts();
      setIsOpen(false);
      setSelectedProduct(false);
    });
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <div className="create_product_modal">
      <div className="modal-content">
        <div className="modal-closeBtn">
          <button className="btn logout" onClick={() => onCloseModal(false)}>
            X
          </button>
        </div>

        <label htmlFor="description">Product description: </label>
        <input
          id="description"
          type="text"
          value={productDetails.description}
          onChange={(e) => onProductChanged(e)}
        />

        <label htmlFor="description_long">Product description_long: </label>
        <input
          id="description_long"
          type="text"
          value={productDetails.description_long}
          onChange={(e) => onProductChanged(e)}
        />

        <label htmlFor="einzelpreis">Product einzelpreis: </label>
        <input
          id="einzelpreis"
          type="number"
          value={productDetails.einzelpreis}
          onChange={(e) => onProductChanged(e)}
        />

        <label htmlFor="active">active: </label>
        <input
          id="active"
          type="checkbox"
          checked={productDetails.active === true}
          onChange={(e) => onProductChanged(e)}
        />

        {/*         <label htmlFor="pic_url">pic_url: </label>
        <input
          id="pic_url"
          type="text"
          value={productDetails.pic_url}
          onChange={(e) => onProductChanged(e)}
        /> */}

        <label htmlFor="pic">pic: </label>
        <input
          type="file"
          id="image"
          accept="image/png, image/jpeg"
          onChange={(e) => handleImageChange(e)}
          required
        />
        <div>
          {selectedProduct === false ? (
            <button
              disabled={manFieldSelected === true ? false : true}
              onClick={() => onCreateProduct()}
              className="createProductBtn"
            >
              create
            </button>
          ) : (
            <button
              disabled={manFieldSelected === true ? false : true}
              onClick={() => onSaveProduct()}
              className="createProductBtn"
            >
              save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProductModal;
