import React, { useState, useEffect } from "react";
//import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/index";
/* import Info from "./info"; */
import { useParams } from "react-router-dom";

import "../css/Status.css";

//
const Status = (props) => {
  const { folgId } = useParams();

  let lieferung = localStorage.getItem("lieferung");
  //let location = localStorage.getItem("location");

  //let history = useHistory();
  useEffect(() => {
    getBaskets();
    getFolgSum();
    getFolg();
    // eslint-disable-next-line
  }, []);
  const [baskets, setBaskets] = useState([]);
  const [folgTotal, setFolgTotal] = useState(0);
  const [fFolgvat, setFolgVat] = useState(0);
  const [fFolgNetto, setFolgNetto] = useState(0);
  const [sCartId, setCarId] = useState(0);
  const [folgDetails, setFolgDetails] = useState([]);

  const getBaskets = () => {
    //let cartId = localStorage.getItem("cartId");
    //console.log(folgId);
    setCarId(folgId);
    if (folgId) {
      axios.get(API_URL + "baskets/" + folgId + "/").then((res) => {
        setBaskets(res.data);
      });
    }
  };

  const getFolgSum = () => {
    //let cartId = localStorage.getItem("cartId");
    if (folgId) {
      axios.get(API_URL + "folgTotal/" + folgId + "/").then((res) => {
        let fValue = res.data;
        setFolgTotal(fValue);
        let folgVat = fValue * 0.07;
        setFolgVat(folgVat.toFixed(2));
        let folgNetto = fValue - folgVat;
        setFolgNetto(folgNetto.toFixed(2));
      });
    }
  };

  const getFolg = () => {
    //let cartId = localStorage.getItem("cartId");
    if (folgId) {
      axios.get(API_URL + "folgStatus/" + folgId + "/").then((res) => {
        setFolgDetails(res.data);
      });
    }
  };

  return (
    <React.Fragment>
      <a className="btn btn__close status__red" href="/">
        Schließen
      </a>
      <div className={folgDetails.statusTextContentClass}>
        <div className="status__container">
          <div className="status__heading__area">
            <div className="status__heading">
              <h3>Status</h3>
            </div>
            <div className={folgDetails.statusTextClass}>
              <p className="status__message__text">
                {folgDetails.statusText}
                <br></br>
                Für Status Update Bitte Seite aktualisieren
              </p>
            </div>
          </div>

          <div className="status__content">
            <div>Bestätigungsnummer:</div>
            <div>
              <strong>{sCartId}</strong>
            </div>
            <div className="status__pickup__info">
              {lieferung === "true" ? (
                <>
                  <div className="checkout__info">
                    <span className="checkout__info__text">
                      Lieferung in ca. einer Stunde an{" "}
                      {folgDetails.pair__namevorname}{" "}
                      {folgDetails.pair__strassenr}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="checkout__info">
                    <span className="checkout__info__text">
                      Abholbereit in 15 Minuten
                    </span>
                  </div>

                  <div>
                    <strong>Keks Fabrik</strong>
                    <div>
                      <a
                        className="status__direction"
                        href="https://goo.gl/maps/qd97YxfrYhr2duCR6"
                      >
                        Wegbeschreibung
                      </a>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="grid--1x2">
              <div className="bottom__bag status__no__margin">
                <div className="yellow__badge">{folgDetails.basketCount}</div>
                {/*   <img src="/assets/img/Bag.png" alt="Eine Einkaufstasche" /> */}
              </div>
              <div className="bottom__text">
                <div>
                  <strong>Vielen Dank für deine Bestellung!</strong>
                </div>
                <div>am {folgDetails.lastchangedDate}</div>
                <div>um {folgDetails.lastchangedTime}</div>
              </div>
            </div>

            <div className="status__picker__details">
              <div className="status__picker__text">
                {lieferung ? (
                  <span>Bestellung wird geliefert</span>
                ) : (
                  <span>
                    {folgDetails.pair__namevorname} holt die Bestellung ab
                  </span>
                )}
              </div>
            </div>

            {baskets.map((basket) => (
              <div key={basket.pk} className="status__basket__list">
                <div className="status__basket__list__details">
                  <strong>
                    <div>
                      {" "}
                      {basket.description}{" "}
                      <span className="status__basket__value">
                        {parseFloat(basket.value).toFixed(2)} €
                      </span>
                    </div>
                  </strong>
                  {basket.addonString ? (
                    <div>Extras: {basket.addonString}</div>
                  ) : null}
                </div>
              </div>
            ))}
            <div>
              <strong>
                Gesammtsumme{" "}
                <span className="status__basket__value">
                  {parseFloat(folgTotal).toFixed(2)} €
                </span>{" "}
              </strong>
            </div>
            <div>
              Inklusive Unsatzsteuer 7%{" "}
              <span className="status__basket__value">
                {" "}
                {parseFloat(fFolgvat).toFixed(2)} €
              </span>
            </div>
            <div className="toppings__seperator"></div>
            <div>
              Gesammtsumme Netto{" "}
              <span className="status__basket__value">
                {parseFloat(fFolgNetto).toFixed(2)} €
              </span>
            </div>
          </div>
          {/*           <div className="infoArea">
            <Info></Info>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Status;
