import React from "react";

const Badge = (props) => {
  return (
    <React.Fragment>
      <div className="yellow__badge">{props.folgCount}</div>
    </React.Fragment>
  );
};

export default Badge;
