import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

/* import Info from "./info"; */
/* import OrderBar from "./orderBar"; */
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../constants/index";
import "../css/Checkout.css";
/* import ReactDOM from "react-dom"; */

/* import { loadScript } from "@paypal/paypal-js";
loadScript({
  "client-id":
    "AdN5F8ciPVMfERecIQH3JF2onRRq-RLMR2kmhLA6_WOl2-PxRIokiR1JICAVHEPntebiVzEBudWgm2Nz",
})
  .then((paypal) => {
    
  })
  .catch((err) => {
    console.error("failed to load the PayPal JS SDK script", err);
  }); */

//
/* const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM }); */
const Checkout = (props) => {
  let navigate = useNavigate();
  let lLieferungsKeys = [
    {
      name: "vName",
      valid: false,
      type: "text",
      displayName: "Vorname",
      value: undefined,
    },
    {
      name: "nName",
      valid: false,
      type: "text",
      displayName: "Nachname",
      value: undefined,
    },
    {
      name: "email",
      valid: false,
      type: "email",
      displayName: "Email",
      value: undefined,
    },
    {
      name: "telefon",
      valid: false,
      type: "tel",
      displayName: "Telefon",
      value: undefined,
    },
    {
      name: "adresse",
      valid: false,
      type: "text",
      displayName: "Straße und Hausnummer",
      prop: "lieferung",
      value: undefined,
    },
    {
      name: "plz_ort",
      valid: false,
      type: "text",
      displayName: "Postleitzahl und Ort",
      prop: "lieferung",
      value: undefined,
    },
  ];
  let lAbholKeys = [
    {
      name: "vName",
      valid: false,
      type: "text",
      displayName: "Vorname",
      value: undefined,
    },
    {
      name: "nName",
      valid: false,
      type: "text",
      displayName: "Nachname",
      value: undefined,
    },
    {
      name: "email",
      valid: false,
      type: "email",
      displayName: "Email",
      value: undefined,
    },
    {
      name: "telefon",
      valid: false,
      type: "tel",
      displayName: "Telefon",
      value: undefined,
    },
  ];
  const [lieferung, setLieferung] = useState();
  const [folgTotal, setFolgTotal] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [clientData, setClientData] = useState();
  const [abholKeys, setAbholkeys] = useState([]);
  const [lieferungKeys, setLieferKeys] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    /* let slieferung = localStorage.getItem("lieferung");  */
    let slieferung = "true";
    let sPlz = localStorage.getItem("plz");
    if (sPlz === null) {
      sPlz = undefined;
    }
    lLieferungsKeys[5].value = sPlz;
    setLieferKeys(lLieferungsKeys);
    setAbholkeys(lAbholKeys);
    setLieferung(slieferung);
    getFolgSum();
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);

  let initialOptions = {
    "client-id":
      "AdN5F8ciPVMfERecIQH3JF2onRRq-RLMR2kmhLA6_WOl2-PxRIokiR1JICAVHEPntebiVzEBudWgm2Nz",
    currency: "EUR",
  };

  const getFolgSum = () => {
    let cartId = localStorage.getItem("cartId");
    if (cartId) {
      axios.get(API_URL + "folgTotal/" + cartId + "/").then((res) => {
        let total = parseFloat(res.data).toFixed(2);
        setFolgTotal(total);
      });
    }
  };

  const onCreateOrder = (paypaldetails) => {
    let updateObj;
    if (paymentMethod === "paypal") {
      updateObj = {
        methodId: 2,
        details: createDetailObject(),
        paypal: paypaldetails,
        // TODO implement PayPal
        //paymentinfo:paypalInfo
      };
    } else {
      updateObj = {
        methodId: 1,
        details: createDetailObject(),
      };
    }
    updateBasket(updateObj);
  };

  const updateBasket = (obj) => {
    let cartId = localStorage.getItem("cartId");
    obj["cartId"] = cartId;
    axios.put(API_URL + "folg/", obj).then((res) => {
      goToPayment();
    });
  };

  const createDetailObject = () => {
    let detailObj;
    //ToDO clean up that shit
    let sLieferung = lieferung;
    // TODO Maybe there is a nicer way to get the details instead of [0].value etc
    if (sLieferung === "true") {
      detailObj = {
        namevorname: lieferungKeys[0].value + " " + lieferungKeys[1].value,
        telefonnr: lieferungKeys[3].value,
        email: lieferungKeys[2].value,
        plzstadt: lieferungKeys[5].value,
        //addzusatz:,
        strassenr: lieferungKeys[4].value,
      };
    } else {
      detailObj = {
        namevorname: abholKeys[0].value + " " + abholKeys[1].value,
        telefonnr: abholKeys[3].value,
        email: abholKeys[2].value,
      };
    }
    return detailObj;
  };

  const goToPayment = () => {
    // save data in BE
    let cartId = localStorage.getItem("cartId");
    navigate("/status/" + cartId);
    localStorage.clear();
  };

  const onChangeInput = (e) => {
    let newValue = e.target.value;
    let curName = e.target.name;
    setClientData({ ...clientData, [curName]: newValue });
    let currObj, bValid;
    switch (curName) {
      case "email":
        bValid = checkValidEmail(newValue);
        break;
      case "telefon":
        bValid = checkValidPhone(newValue);
        break;
      default:
        bValid = checkValidString(newValue);
    }

    if (lieferung === "true") {
      currObj = lieferungKeys.filter((obj) => {
        return obj.name === curName;
      });
      currObj[0].valid = bValid;
      currObj[0].value = newValue;
      setLieferKeys(lieferungKeys);
    } else {
      currObj = abholKeys.filter((obj) => {
        return obj.name === curName;
      });
      currObj[0].valid = bValid;
      currObj[0].value = newValue;
      setAbholkeys(abholKeys);
    }
    checkIfValid();
  };

  const checkValidEmail = (sEmail) => {
    let re =
      //eslint-disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(sEmail)) {
      return true;
    } else {
      return false;
    }
  };

  const checkValidString = (sText) => {
    if (sText.length > 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkValidPhone = (sText) => {
    if (sText.length > 4) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfValid = () => {
    let bValid = true;
    if (lieferung === "true") {
      lieferungKeys.forEach((lief) => {
        if (!lief.valid) {
          bValid = false;
        }
      });
    } else {
      abholKeys.forEach((lief) => {
        if (!lief.valid) {
          bValid = false;
        }
      });
    }
    /*     if (paymentMethod === undefined) {
      bValid = false;
    } */
    setFormValid(bValid);
  };

  const onCancel = () => {
    let cartId = localStorage.getItem("cartId");
    navigate("/shoppingcart/" + cartId);
  };

  const onPaymentChange = (sString) => {
    //let fTotal = parseFloat(folgTotal);
    if (sString === "paypal") {
      setShow(true);
      setLieferung("true");
      //fTotal = fTotal + 4.9;
    } else {
      setLieferung("false");
      setShow(false);
      //fTotal = fTotal - 4.9;
    }
    //etFolgTotal(fTotal);

    setPaymentMethod(sString);
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: folgTotal,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      // send BE request to save a few paypal details
      // navigate to status
      //const name = details.payer.name.given_name;
      onCreateOrder(details);
    });
  };

  return (
    <React.Fragment>
      <PayPalScriptProvider options={initialOptions}>
        <div className="checkout__content">
          <div>
            <button
              onClick={() => {
                onCancel();
              }}
              className="chackout__cancel__btn"
            >
              Abbrechen
            </button>
          </div>
          <div className="checkout__grid">
            <div>
              <div className="checkout__heading">
                <h3>Checkout</h3>
                {lieferung === "true" ? (
                  <>
                    <h4>Bestellung zum Liefern</h4>
                    <div className="checkout__info">
                      <span className="checkout__info__text">
                        Lieferung {/* einer Stunde */}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <h4>Bestellung zum Abholen</h4>
                    <div className="checkout__info">
                      <span className="checkout__info__text">
                        Abholbereit während der Öffnungszeiten
                      </span>
                    </div>
                  </>
                )}
                <div className="checkout__payment__details">
                  <strong>
                    <span>Zu bezahlen</span>
                    <span className="checkout__total__price">
                      {folgTotal}
                      {/*                       {lieferung === "true" ? (
                        <>{folgTotal}</>
                      ) : (
                        <>{parseFloat(folgTotal).toFixed(2)}</>
                      )} */}
                      €
                    </span>
                  </strong>
                </div>
              </div>
              <div className="checkout__picker__details">
                {lieferung === "true" ? (
                  <>
                    <h5>Wohin soll geliefert werden?</h5>
                  </>
                ) : (
                  <>
                    <h5>Wer holt die Bestellung ab?</h5>
                  </>
                )}

                <form className="" action="">
                  {lieferung === "true"
                    ? lieferungKeys.map((obj, key) => (
                        <div key={key}>
                          <div>
                            <label htmlFor="{obj.name}">
                              {obj.displayName}
                            </label>
                          </div>
                          <div className="inputContainer">
                            {obj.valid === true ? (
                              <div className="checkout__input__hacken">
                                <img
                                  src="/assets/img/checked.png"
                                  alt="Ein Häckchen"
                                />
                              </div>
                            ) : null}

                            <input
                              className="checkout__input"
                              type={obj.type}
                              id={obj.name}
                              name={obj.name}
                              onChange={(e) => onChangeInput(e)}
                            ></input>
                          </div>
                        </div>
                      ))
                    : abholKeys.map((obj, key) => (
                        <div key={key}>
                          <div>
                            <label htmlFor="{obj.name}">
                              {obj.displayName}
                            </label>
                          </div>
                          <div className="inputContainer">
                            {obj.valid === true ? (
                              <div className="checkout__input__hacken">
                                <img
                                  src="/assets/img/checked.png"
                                  alt="Ein Häckchen"
                                />
                              </div>
                            ) : null}

                            <input
                              className="checkout__input"
                              type={obj.type}
                              id={obj.name}
                              name={obj.name}
                              onChange={(e) => onChangeInput(e)}
                            ></input>
                          </div>
                        </div>
                      ))}
                </form>
              </div>
            </div>
            <div>
              <div className="checkout__payment__select">
                <h5>Zahmittel auswählen</h5>

                <label
                  className="checkout__payment__select__details"
                  htmlFor="paypal"
                >
                  <input
                    id="paypal"
                    type="radio"
                    name="payment"
                    value="paypal"
                    onClick={() => onPaymentChange("paypal")}
                  ></input>
                  <span className="checkout__payment__checkmark"></span>
                  <div className="checkout__payment__checkmark__label">
                    <span>PayPal</span>
                  </div>
                </label>
                <br />
                <label
                  className="checkout__payment__select__details"
                  htmlFor="bar"
                >
                  <input
                    id="bar"
                    type="radio"
                    name="payment"
                    value="bar"
                    onClick={() => onPaymentChange("bar")}
                  ></input>
                  <span className="checkout__payment__checkmark"></span>
                  <div>
                    <span className="checkout__payment__checkmark__label">
                      Bar bei Abholung
                    </span>
                  </div>
                </label>
                <br />

                {show && formValid && paymentMethod === "paypal" ? (
                  <PayPalButtons
                    createOrder={createOrder}
                    onApprove={(data, actions) => onApprove(data, actions)}
                  />
                ) : null}
              </div>

              {formValid && paymentMethod !== undefined ? (
                paymentMethod === "bar" ? (
                  <div className="checkout__form__completed">
                    <button
                      className="checkout__gotopay__btn"
                      onClick={() => onCreateOrder()}
                    >
                      <strong>
                        Jetzt bezahlen
                        <img
                          className="btn__arrow__right"
                          src="/assets/img/arrow_right.png"
                          alt="Ein Pfeil nach Rechts"
                        />
                      </strong>
                    </button>
                  </div>
                ) : null
              ) : (
                <div className="checkout__warning">
                  <div className="checkout__warning__text">
                    <span>
                      Bitte alle Felder ausfüllen und eine Zahlungsmethode
                      auswählen.
                    </span>
                  </div>
                </div>
              )}

              <div className="checkout__ds_ag">
                <p className="checkout__ds_ag_text">
                  Durch Klicken auf »Bestellen« erkläre ich, dass ich mit den
                  <a href="/ag" className="checkout__ag">
                    {" "}
                    Allgemeinen Geschäftsbedingungen
                  </a>{" "}
                  einverstan-den bin und die{" "}
                  <a className="checkout__ag" href="/datenschutz">
                    Datenschutzrichtlinien
                  </a>{" "}
                  gelesen habe.
                </p>
              </div>
            </div>
          </div>
          {/*         <div className="infoArea">
          <Info></Info>
        </div> */}
        </div>
      </PayPalScriptProvider>
    </React.Fragment>
  );
};
//}

export default Checkout;
