import React, { useState } from "react";
import { login } from "../../services/userService.js";
import FormInputs from "../../common/FormInputs.jsx";

const Login = ({ setUser, ...props }) => {
  const [loginUser, setLoginUser] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(loginUser);
      setUser(response.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrors(ex.response.data);
      }
    }
  };

  return (
    <div className="register">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <FormInputs input={loginUser} setInput={setLoginUser} errors={errors} />
        <input type="submit" value="submit" />
      </form>
    </div>
  );
};

export default Login;
